
import { defineComponent, ref, onMounted, computed, onUpdated } from "vue";
import ExportCustomerModal from "@/components/modals/forms/ExportCustomerModal.vue";
import AddSalesOfferModal from "@/components/modals/forms/AddSalesOfferModal.vue";
import AddProductListSalesEnquiry from "@/components/modals/forms/AddProductListSalesEnquiry.vue";
// import Datatable from "@/components/kt-datatable/KTDatatable.vue";

import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
// import MixedWidget7 from "@/components/widgets/mixed/Widget7.vue";
import MixedWidget10 from "@/components/widgets/mixed/Widget10.vue";
import customers from "@/core/data/customers";
import roc_list, { business_types } from "@/core/data/genericData";
import { useRoute, useRouter } from "vue-router";
import { ICustomer } from "@/core/data/customers";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { array } from "yup/lib/locale";
import JwtService from "@/core/services/JwtService";
import { Modal } from "bootstrap";
import { ActionsFi } from "@/store/enums/StoreFiEnums";
import moment from "moment";

export default defineComponent({
  name: "customers-listing",
  components: {
    ExportCustomerModal,
    AddSalesOfferModal,
    AddProductListSalesEnquiry,
    // MixedWidget7,
    MixedWidget10,
    // Datatable,
  },

  setup() {
    const count = ref(0);
    const store = useStore();
    const checkedCompany = ref([]);
    const loadingData = ref<boolean>(true);
    const listVisible = ref<boolean>(true);
    const router = useRouter();
    const searchTearm = ref("");

    const formData = ref({
      startDate: "",
      endDate: "",
      enq_no: "",
    });

    interface WIJournal {
      sales_inquiry_id: string;
      sales_inquiry_details_id: string;
      sales_inquiry_time: string;
      product_detail: string;
      status_name: string;
      required_qty: string;
      cust_name: string;
      cat_name:string;
      mfg_company_json:string;
      se_offer_id: string;
      customer_type: string;
      delivery_address: string;
      uom: string;
    }

    interface WICompanies {
      company_id: number;
      user: {
        avatar: string;
        name: string;
        group: string;
      };
      comp_type: string;
      status: {
        label: string;
        color: string;
      };
      active: {
        label: string;
        color: string;
      };
      roc: string;
      nob: string;
      doi: string;
      pan: string;
    }

    var paginationData = ref({});

    paginationData.value = {
      start: 0,
      end: 0,
      total: 0,
      perPage: 5,
      activePage: 1,
      totPage: 1,
      pageRange: [1],
    };

    const refreshData = () => {
      paginationData.value["activePage"] = 1;
      getCompanyList(searchTearm.value);
    };

    const changePageChange = (page) => {
      paginationData.value["activePage"] = page;
      getCompanyList(searchTearm.value);
    };

    var tableData = ref<Array<WIJournal>>([]);

    const getCompanyList = async (data) => {
      //loadingData.value = true;
      var user = JSON.parse(JwtService.getToken());
      var start_data = ""
        var end_date = ""
      if(!formData.value['startDate']){
          start_data = ""
          end_date = ""
      }
      else
      {
        start_data = moment(formData.value.startDate[0]).format("YYYY-MM-DD")
        end_date = moment(formData.value.startDate[1]).format("YYYY-MM-DD")
      }
      try {
        var values = {
          user_id : user.user_id,
          se_no : formData.value.enq_no,
          start_date: start_data,
          end_date: end_date,
          page: parseInt(paginationData.value["activePage"]),
          records_per_page: parseInt(paginationData.value["perPage"]),
          
        };
        console.log(values);
        await store
          .dispatch(ActionsFi.CUST_GET_SALES_OFFER_LISTING_DATA, values)
          .then((data) => {
            debugger;
            console.log("total data:" + JSON.stringify(data));

            debugger;
            // data.data = data.data.map((item) => {
            //   let typeValue = type_data.value.filter((type) => {
            //     return (
            //       type.stock_product_type_id === item.stock_product_type_id
            //     );
            //   });
            //   item.stock_product_type = typeValue[0]?.stock_product_type;
            //   return item;
            // });
            tableData.value = [];
            const totalCount = data.data.total_records;

            // set pagination
            paginationData.value["total"] = data?.data?.total_records;
            paginationData.value["start"] = data?.data?.records_from;
            paginationData.value["end"] = data?.data?.records_upto;
            paginationData.value["activePage"] = data?.data?.page;
            paginationData.value["totPage"] = data?.data?.total_pages;
            paginationData.value["pageRange"] = [];
            for (
              let index = data?.data?.bar_range_start;
              index < data?.data?.bar_range_end;
              index++
            ) {
              paginationData.value["pageRange"].push(index);
            }
            //endpagination
            data = data?.data?.result_list;

            var resultsM = ref<Array<WIJournal>>([]);
            var status_label = "";
            var status_color = "";
            var active_label = "";
            var active_color = "";
            

            for (let j = 0; j < data.length; j++) {
              resultsM.value = Array({
                sales_inquiry_id: data[j]["se_detail_id"],
                sales_inquiry_details_id: data[j]["se_no"],
                se_offer_id: data[j]["se_offer_id"],
                sales_inquiry_time: data[j]["se_time"],
                cust_name: data[j]["customer_name"],
                customer_type: data[j]["customer_type"],
                cat_name:data[j]['category_name'],
                product_detail: data[j]["product_desc"],
                required_qty: data[j]["required_quantity"],
                delivery_address: data[j]["delivery_address"],
                status_name: data[j]["status"],
                mfg_company_json: data[j]['mfg_company_json'],
                uom: data[j]['uom']
              });
              console.log("resultsm:" + JSON.stringify(resultsM.value));
              tableData.value.splice(
                j,
                resultsM.value.length,
                ...resultsM.value
              );
            }

            loadingData.value = false;
          })
          .catch(({ response }) => {
            tableData.value = [];
            loadingData.value = false;
          });
      } catch (e) {
        console.log(e);
      }
    };

    // onUpdated(() => {          });

    // var tableData = ref<Array<WICompanies>>([]);
    const initCustomers = ref<Array<WIJournal>>([]);

    onMounted(async () => {
      await getCompanyList(searchTearm.value);
      setCurrentPageBreadcrumbs("Sales Offer", []);
      initCustomers.value.splice(0, tableData.value.length, ...tableData.value);
    });

    const product_add_modal = (sales_enquiry_id) => {
      const modal = new Modal(
        document.getElementById("kt_modal_add_product_sales_enquiry")
      );
      modal.show();
      (document.querySelector("#sales_enquiry_id") as HTMLInputElement).value =
        sales_enquiry_id;
    };

    const search = ref<string>("");
    const searchItems = () => {
      searchTearm.value = search.value;
      getCompanyList(search.value);
      console.log(search.value);
      // tableData.value.splice(0, tableData.value.length, ...initCustomers.value);
      // if (search.value !== "") {
      //   let results: Array<WICompanies> = [];
      //   for (let j = 0; j < tableData.value.length; j++) {
      //     if (searchingFunc(tableData.value[j], search.value)) {
      //       results.push(tableData.value[j]);
      //     }
      //   }
      //   tableData.value.splice(0, tableData.value.length, ...results);
      // }
    };
    function showList() {
      listVisible.value = true;
      console.log("list visible.." + showList);
    }
    function hideList() {
      listVisible.value = false;
    }
    const searchingFunc = (obj, value): boolean => {
      for (let key in obj) {
        if (!Number.isInteger(obj[key]) && !(typeof obj[key] === "object")) {
          if (obj[key].indexOf(value) != -1) {
            return true;
          }
        }
      }
      return false;
    };

    const Pdfdata = async (id) => {
      var user = JSON.parse(JwtService.getToken());
      const db_data = {
        "user_id" : user.user_id,
        "doc_type" : 11,
        "doc_id" : id
      };
      console.log("DB DATA")
      console.log(db_data)
      //loading.value = true;
      try {
        await store
          .dispatch(ActionsFi.CUST_PDF_REQUEST, db_data)
          .then(({ data }) => {
           console.log("DATATATATA")
           console.log(data.request_id)
           console.log('https://print.elogicals.com/print/sf/' + db_data.doc_id + '/' +db_data.user_id + '/' + data.request_id)
            //loading.value = false;
            window.open('https://print.elogicals.com/print/sf/' + db_data.doc_id + '/' +db_data.user_id + '/' + data.request_id )
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    };

    return {
      formData,
      tableData,
      search,
      searchItems,
      checkedCompany,
      count,
      getCompanyList,
      paginationData,
      changePageChange,
      refreshData,
      loadingData,
      product_add_modal,
      showList,
      listVisible,
      hideList,
      router,
      Pdfdata
    };
  },
});
